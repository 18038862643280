import { Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { useForm } from "react-hook-form";

export default function List(props) {
    /**
     * Form variables
     */
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    /**
     * List of children
     */
    const [children, setChildren] = useState([]);

    // Setup variables for getting list values

    /**
     * Setup variables for getting list values
     */
    const [listNameCode, setListNameCode] = useState("");
    const [listNameCodeOther, setListNameCodeOther] = useState("");
    const [listName, setListName] = useState("");
    const [listNameOther, setListNameOther] = useState("");

    /**
     * List of children with pages specified
     */
    const [childrenPages, setChildrenPages] = useState([]);

    /**
     * If email sent
     */
     const [emailSent, setEmailSent] = useState(<span></span>);

    /**
     * Show add me e-mail form
     */
    const [parentAdd, setParentAdd] = useState(false);

    /**
     * Search value when changed
    */
    const [updateValueSearch, setUpdateValueSearch] = useState({
        search: '',
    });

    /**
     * Number of total children from query
    */
    const [numChildrenTotal, setNumChildrenTotal] = useState(0);

    /**
     * Pages user is on
    */
    const [pageNum, setPageNum] = useState(1);

    /**
     * Handles changes to the search form
    */
    const handleChangeSearch = (event) => {
        // Name and value of target
        const { name, value } = event.target;

        setUpdateValueSearch((prevalue) => {
            return {
                ...prevalue,
                [name]: value
            }
        });
    };

    /**
     * Determine whether to show add me form
    */
    const parentAddMe = (e) => {
        e.preventDefault();

        // Show form
        setParentAdd(true);

        // Scroll to top of page
        window.scrollTo(0, 0);
    };

    /**
     * On add me form submit
    */
    const onSubmit = (e) => {
        Axios.post("/addme", {
            fullname: e.fullname,
            email: e.email,
        }).then((response) => {
            // Return to list
            setParentAdd(false);

            // Clear values
            setValue('email', '');
            setValue('fullname', '');

            // Set message
            setEmailSent(<span className="success">E-mail sent!</span>);
        });
    }

    /**
     * Creates a list of pages based on children list count
    */
    const createPageNumbers = () => {
        let elements = [];

        try {
            for (let i = 1; i <= Math.ceil(children[0].length / 10); i++) {
                if (pageNum == i) {
                    // User on this page
                    elements.push(<li key={i} className="selected-page"><Link to="#/" onClick={() => setPageNum(i)}>{i}</Link></li>);
                } else {
                    // User not on this page
                    elements.push(<li key={i}><Link to="#/" onClick={() => setPageNum(i)}>{i}</Link></li>);
                }
            }
        } catch {
            // Nothing
        }

        return elements;
    }

    /**
     * On page load
    */
    useEffect(() => {
        /**
         * Handles get children request
        */
        Axios.get("/allchildren", {
            params: {
                status: props.status,
            }
        }).then((response) => {
            // Set up select array
            let array = [];

            // Set total number of children from query
            setNumChildrenTotal(response.data.resultCount[0].count);

            // Iterate through results
            for (let i = 0; i <= response.data.result.length - 1; i++) {
                array.push(
                    <tr key={i}>
                        <td data-label="Name">{response.data.result[i].name}</td>
                        <td data-label="Reason">
                            <i>{response.data.result[i].reason}</i>
                            {response.data.result[i].latest_audio_file 
                                ? (
                                    <>
                                        <br />
                                        <audio controls>
                                            <source 
                                                src={
                                                    process.env.REACT_APP_SERVER_URL + 
                                                    response.data.result[i].latest_audio_file.slice(1)
                                                } 
                                                type="audio/mpeg" 
                                            />
                                            Your browser does not support the audio element.
                                        </audio>
                                        <br />
                                        From Santa Claus on 
                                        {(() => {
                                            // Parse the raw date
                                            const rawDate = response.data.result[i].audio_date_created;
                                            const date = new Date(rawDate);

                                            // Format the date
                                            const month = date.toLocaleString('en-US', { month: 'long' }); // "December"
                                            const day = date.getDate(); // "20"
                                            const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
                                            const minutes = date.getMinutes().toString().padStart(2, '0'); // "44"
                                            const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // "PM"

                                            // Return formatted date
                                            return ` ${month} ${day} at ${hours}:${minutes}${ampm}`;
                                        })()}.
                                    </>
                                ) : null // Render nothing if no audio file exists
                            }
                        </td>
                    </tr>
                );
            }

            // Combine arrays
            array = array.concat(generateChildren(props.status));

            // Sort array by ABC
            array.sort((a, b) => (a.props.children[0].props.children.toLowerCase() > b.props.children[0].props.children.toLowerCase()) ? 1 : ((b.props.children[0].props.children.toLowerCase() > a.props.children[0].props.children.toLowerCase()) ? -1 : 0));

            // Set select array
            setChildren([array]);
        });

        // Set variable for list names
        if (props.status == 0) {
            setListName("Naughty");
            setListNameCode("naughty");
            setListNameOther("Nice");
            setListNameCodeOther("nice");
            props.setPageTitle('Santa\'s Naughty List');
        } else {
            setListName("Nice");
            setListNameCode("nice");
            setListNameOther("Naughty");
            setListNameCodeOther("naughty");
            props.setPageTitle('Santa\'s Nice List');
        }

        // Clear e-mail sent
        setEmailSent(<span></span>);
    }, []);

    useEffect(() => {
        loadPage();
    }, [children]);

    useEffect(() => {
        loadPage();
    }, [pageNum]);

    /**
     * Loads children based on page data
     */
    const loadPage = () => {
        // Don't allow page less than 0
        if (pageNum <= 0) { return false; }

        let elements = [];

        try {
            for (let i = ((pageNum - 1) * 10); i <= (((pageNum - 1) * 10) + 10) - 1; i++) {
                // Only insert into array if has a valid value
                if (typeof (children[0][i]) !== 'undefined' && children[0][i] != null) {
                    elements.push(children[0][i]);
                }
            }

            setChildrenPages([elements]);
        } catch (error) {
            // Nothing
        }
    }

    /**
     * Generates random children
     * 
     * @param int stauts The integer representation for naughty or nice list
     * @return Returns an array of random robot children
    */
    const generateChildren = (status) => {
        let childNames = ['Matthew', 'Joseph', 'Megan', 'Ella', 'Lauren', 'Lisa', 'Brooke', 'Erin', 'Caitlyn', 'Allie'];
        let lastNames = ['Smith', 'Johnson', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez', 'Thomas', 'Taylor'];
        let goodReason = ['Helped a friend in need.', 'Cleaned their room.', 'Listens to their parents.'];
        let badReason = ['Bullies others.', 'Does not listen to their parents.'];

        let array = [];

        // Create robots
        for (let i = 0; i <= 50; i++) {
            // Set up varibles
            let randomName = Math.floor(Math.random() * childNames.length);
            let randomLastName = Math.floor(Math.random() * lastNames.length);
            let randomReason = '';
            let reason = [];

            // Check status and give reasons based on result
            if (status == 0) {
                randomReason = Math.floor(Math.random() * badReason.length);
                reason = badReason;
            } else {
                randomReason = Math.floor(Math.random() * goodReason.length);
                reason = goodReason;
            }

            array.push(
                <tr key={i + "_robot"}>
                    <td data-label="Name">{childNames[randomName] + " " + lastNames[randomLastName]}</td>
                    <td data-label="Reason"><i>{reason[randomReason]}</i></td>
                </tr>
            );
        }

        return array;
    }

    /**
     * Loads children based on search query
     */
    const loadSearch = (e) => {
        e.preventDefault();

        Axios.get("/search", {
            params: {
                search: updateValueSearch.search,
                status: props.status,
            }
        }).then((response) => {
            // Set up select array
            let array = [];

            // Iterate through results
            for (let i = 0; i <= response.data.result.length - 1; i++) {
                array.push(
                    <tr key={i}>
                        <td data-label="Name">{response.data.result[i].name}</td>
                        <td data-label="Reason">
                            <i>{response.data.result[i].reason}</i>
                            {response.data.result[i].latest_audio_file 
                                ? (
                                    <>
                                        <br />
                                        <audio controls>
                                            <source 
                                                src={
                                                    process.env.REACT_APP_SERVER_URL + 
                                                    response.data.result[i].latest_audio_file.slice(1)
                                                } 
                                                type="audio/mpeg" 
                                            />
                                            Your browser does not support the audio element.
                                        </audio>
                                        <br />
                                        From Santa Claus on 
                                        {(() => {
                                            // Parse the raw date
                                            const rawDate = response.data.result[i].audio_date_created;
                                            const date = new Date(rawDate);

                                            // Format the date
                                            const month = date.toLocaleString('en-US', { month: 'long' }); // "December"
                                            const day = date.getDate(); // "20"
                                            const hours = date.getHours() % 12 || 12; // Convert to 12-hour format
                                            const minutes = date.getMinutes().toString().padStart(2, '0'); // "44"
                                            const ampm = date.getHours() >= 12 ? 'PM' : 'AM'; // "PM"

                                            // Return formatted date
                                            return ` ${month} ${day} at ${hours}:${minutes}${ampm}`;
                                        })()}.
                                    </>
                                ) : null // Render nothing if no audio file exists
                            }
                        </td>
                    </tr>
                );
            }

            setPageNum(0);

            // Set select array
            setChildrenPages([array]);
        });
    }

    if (!parentAdd) {
        return (
            <div>
                {emailSent}

                <form action="" method="post">
                    <b><u>Santa's Magic Search:</u></b><br />
                    <input type="text" name="search" placeholder="What is your name? Type it here." onChange={handleChangeSearch} />
                    <input type="submit" value="Search" onClick={loadSearch} />

                    <p>
                        <b>Check daily for updates!</b>
                    </p>

                    <p>
                        <i>Cannot find your name? Check the <Link onClick={useEffect} to={"/" + listNameCodeOther + "/"}>{listNameOther} List</Link>.</i>
                    </p>
                </form>

                <div className="main-table">
                    {childrenPages[0] && childrenPages[0].length > 0 ?
                        <table>
                            <thead>
                                <tr>
                                    <th scope="col">Name</th>
                                    <th scope="col">Reason</th>
                                </tr>
                            </thead>

                            <tbody>
                                {childrenPages}
                            </tbody>
                        </table> : <p className="center-content"><b>Nothing to display.</b></p>}
                </div>

                <div className="center-content">
                    <a href="https://www.SantasWishlistMaker.com">Tell Santa Claus what you want for Christmas, click here to get started!</a>
                    <br /><br />
                    <Link onClick={parentAddMe} to="#/"><img src={"/images/cantfindname" + listNameCodeOther + ".png"} alt="Can\'t find your name?" /></Link>
                </div>

                <br /><br />

                <div className="pagination-main">
                    <ul>
                        <li key="0">Page</li>

                        {createPageNumbers()}
                    </ul>
                </div>
            </div>
        );
    } else {
        return (
            <div>
                <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>
                    <p>
                        Having trouble finding your name on the list? With so many kids in the world, the elves are having a hard time keeping the list up-to-date. Tell your parents to visit this website and Santa's Web Division will be right on it. You can use this form to help your parents find this website.
                    </p>

                    <div className="input-box">
                        <b>Parent's E-mail: </b>
                        <input
                            type="text"
                            {...register("email", {
                                required: "Please enter your parent's e-mail address.",
                                pattern: {
                                    value: /\S+@\S+\.\S+/,
                                    message: "Enter a valid e-mail address."
                                }
                            })}
                        />

                        {errors.email && <span role="form-error">{errors.email.message}</span>}
                    </div>

                    <div className="input-box">
                        <b>Your Name: </b>
                        <input
                            {...register("fullname", {
                                required: "Enter your name."
                            })}
                            type="text"
                        />

                        {errors.fullname && <span role="form-error">{errors.fullname.message}</span>}
                    </div>

                    <div className="input-box">
                        <button type="submit">Send to Santa!</button>
                    </div>
                </form>
            </div>
        );
    }
}