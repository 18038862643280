import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { useForm } from "react-hook-form";
import Axios from "axios";
import CheckoutForm from "./CheckoutForm";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is your publishable API key.
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_LIVE);

export default function AddChildren(props) {
    const { register, handleSubmit, setValue, formState: { errors } } = useForm();

    /**
     * Create years for child birthday
    */
    const years = [];

    // Creates years for child birthdays
    for (let i = new Date().getFullYear(); i >= 1900; i--) {
        years.push(<option key={i}>{i}</option>);
    }

    /**
     * Handles add child submission
    */
    const onSubmit = (e) => {
        Axios.post("/addchild", {
            data: e,
        }).then((response) => {
            // Reset form
            setValue("childName", "");
            setValue("month", 1);
            setValue("day", 1);
            setValue("year", 2022);
            setValue("status", 0);
            setValue("reason", "");

            // Remove credit
            props.setCredits(props.credits - 1);
        });
    }

    const [clientSecret, setClientSecret] = useState("");

    /**
     * Has user paid
     */
    const [userPaid, setUserPaid] = useState(false);

    useEffect(() => {
        // Get Credits
        props.getCredits();

        /**
         * Get user info
        */
        Axios.get("/myuserinfo").then((response) => {
            if(response.data.result[0].paid == 1) {
                setUserPaid(true);
            }
        });

        // Create PaymentIntent as soon as the page loads
        fetch(`${process.env.REACT_APP_PAYMENT_URL}`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ user_id: props.userID }),
        })
            .then((res) => res.json())
            .then((data) => setClientSecret(data.clientSecret));
    }, []);

    const appearance = {
        theme: 'stripe',
    };

    /**
     * Stripe options
     */
    const options = {
        clientSecret,
        appearance,
    };

    if (props.credits > 0) {
        return (
            <div>
                <h4>Add Children to List - {props.credits} Remaining</h4>
                <form action="" method="post" onSubmit={handleSubmit(onSubmit)}>

                    <div className="input-box">
                        <b>Child name:</b>
                        <br />
                        <small><i>This will be the name displayed on the site.</i></small>
                        <br />
                        <input
                            type="text"
                            maxLength="50"
                            {...register("childName", {
                                required: "Please enter your child's name.",
                            })}
                        />

                        {errors.childName && <span role="form-error">{errors.childName.message}</span>}
                    </div>

                    <div className="input-box">
                        <b>Birthday</b>
                        <br />
                        <select
                            {...register("month")}>
                            <option value="1">January</option>
                            <option value="2">February</option>
                            <option value="3">March</option>
                            <option value="4">April</option>
                            <option value="5">May</option>
                            <option value="6">June</option>
                            <option value="7">July</option>
                            <option value="8">August</option>
                            <option value="9">September</option>
                            <option value="10">October</option>
                            <option value="11">November</option>
                            <option value="12">December</option>
                        </select>

                        <select
                            {...register("day")}>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                            <option value="24">24</option>
                            <option value="25">25</option>
                            <option value="26">26</option>
                            <option value="27">27</option>
                            <option value="28">28</option>
                            <option value="29">29</option>
                            <option value="30">30</option>
                            <option value="31">31</option>
                        </select>

                        <select
                            {...register("year")}>
                            {years}
                        </select>
                    </div>

                    <div className="input-box">
                        <b>Naughty or Nice?</b>
                        <br />
                        <select
                            {...register("status")}>
                            <option value="0">Naughty</option>
                            <option value="1">Nice</option>
                        </select>
                    </div>

                    <div className="input-box">
                        <b>Reason:</b>
                        <br />
                        <small><i>What is their reason for being naughty or nice?</i></small>
                        <br />
                        <textarea
                            rows="5"
                            {...register("reason", {
                                required: "Please enter a reason for why your child is naughty or nice.",
                            })}>
                        </textarea>

                        {errors.reason && <span role="form-error">{errors.reason.message}</span>}

                        <span className="small-font">
                            <b>To protect your child from inappropriate content, our filter will change any reason to "Santa Claus is in the process of updating your status!", if detected to contain inappropriate or misleading key phrases.</b>
                        </span>
                    </div>

                    <div className="input-box">
                        <button type="submit">Add to List!</button>
                    </div>
                </form>

                <hr />
            </div>
        )
    } else {
        return (
            <div>
                <p>
                    You have no credits to add children.
                </p>

                <p>
                    <b>Purchase {!userPaid ? 'Access & ' : ''} Two (2) Credits - $5.00:</b>
                </p>

                <div className="StripeForm">
                    {clientSecret && (
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    )}
                </div>

                <p>
                    <b>50% of all proceeds are donated to Toys for Tots.<br />Your purchase will contribute $2.50 to Toys for Tots!</b>
                </p>

                <p>
                    If you are not happy with your purchase, e-mail us for a refund <a href="mailto:support@drennansoftware.com">here</a>.
                </p>

                <p>
                    <iframe width="70%" height="315" src="https://www.youtube.com/embed/U7BvTKca3Fc?rel=0&autoplay=0&amp;controls=0&amp;showinfo=0" frameBorder="0" allowFullScreen></iframe>
                </p>

                <hr />
            </div>
        );
    }
}